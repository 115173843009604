import { useEffect, useState } from "react";

import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import { ClickableContentWrapper } from "studio-design-system";

import { useAuth } from "~/hooks/auth/useAuth";
import { getDoesUserHaveClasses } from "~/util/user-class-info-utils";

import * as styles from "./MarketingHeaderWithAuth.css";

const MobileNavigation = ({
  menuActive,
  handleMenuClick,
  onSignIn,
  onSignUp,
  onSignOut,
  onContactSupportClick,
  setMenuActive,
}: {
  menuActive: boolean;
  handleMenuClick: () => void;
  onSignIn: () => void;
  onSignUp: () => void;
  onSignOut: () => void;
  onContactSupportClick: () => void;
  setMenuActive: (isActive: boolean) => void;
}) => {
  const router = useRouter();
  const { user, loadingCurrentUser } = useAuth();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(!!user);

  useEffect(() => {
    setIsUserLoggedIn(!!user);
  }, [user, loadingCurrentUser]);

  return (
    <div className={styles.mobileNavigationWrap}>
      <nav
        className={classNames(styles.mobileNavigation, menuActive && styles.mobileNavigationActive)}
      >
        <ul className={styles.mobileNavItems}>
          {isUserLoggedIn ? (
            <>
              <li className={styles.mobileNavItem} onClick={handleMenuClick}>
                <Link href={"/library"}>
                  <span
                    className={classNames(
                      styles.mobileNavItemLink,
                      router.pathname == "/library" && styles.activeLink,
                    )}
                  >
                    {" "}
                    My Library{" "}
                  </span>
                </Link>
              </li>
              <li className={styles.mobileNavItem} onClick={handleMenuClick}>
                <Link href={"/browse"}>
                  <span
                    className={classNames(
                      styles.mobileNavItemLink,
                      router.pathname == "/browse" && styles.activeLink,
                    )}
                  >
                    {" "}
                    Browse{" "}
                  </span>
                </Link>
              </li>
              <li className={styles.mobileNavItem} onClick={handleMenuClick}>
                <Link href={user?.currentSessionSlug ? "/school/music" : "/schools/music"}>
                  <span
                    className={classNames(
                      styles.mobileNavItemLink,
                      router.pathname == "/schools/music" && styles.activeLink,
                    )}
                  >
                    {" "}
                    Music School{" "}
                  </span>
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className={styles.mobileNavItem}>
                <button
                  onClick={onSignIn}
                  className={classNames(
                    styles.mobileNavItemLink,
                    styles.navItemLinkBold,
                    "nav-item",
                  )}
                >
                  {" "}
                  Log in{" "}
                </button>
              </li>
              <li className={styles.mobileNavItem}>
                <button
                  onClick={onSignUp}
                  className={classNames(
                    styles.mobileNavItemLink,
                    styles.navItemLinkBold,
                    "nav-item",
                  )}
                >
                  {" "}
                  Sign up{" "}
                </button>
              </li>
            </>
          )}
          <li className={styles.divider}></li>

          {isUserLoggedIn ? (
            <>
              {getDoesUserHaveClasses(user) && (
                <li className={styles.mobileNavItem} onClick={handleMenuClick}>
                  <Link href={"/history"}>
                    <span
                      className={classNames(
                        styles.mobileNavItemLink,
                        router.pathname == "/history" && styles.activeLink,
                      )}
                    >
                      {" "}
                      My Class History{" "}
                    </span>
                  </Link>
                </li>
              )}
              <li className={styles.mobileNavItem} onClick={handleMenuClick}>
                <Link href={"/settings"}>
                  <span
                    className={classNames(
                      styles.mobileNavItemLink,
                      router.pathname == "/settings" && styles.activeLink,
                    )}
                  >
                    {" "}
                    Account Settings{" "}
                  </span>
                </Link>
              </li>
            </>
          ) : (
            <li className={styles.mobileNavItem} onClick={handleMenuClick}>
              <Link href={"/browse"}>
                <span
                  className={classNames(
                    styles.mobileNavItemLink,
                    router.pathname == "/browse" && styles.activeLink,
                  )}
                >
                  {" "}
                  Browse{" "}
                </span>
              </Link>
            </li>
          )}

          {/* {(!isUserLoggedIn || !isCreator) && (
            <li className={styles.mobileNavItem} onClick={handleMenuClick}>
              <Link href={"/creators"}>
                <span
                  className={classNames(
                    styles.mobileNavItemLink,
                    router.pathname == "/creators" && styles.activeLink,
                  )}
                >
                  {" "}
                  {FOR_CREATORS_TEXT}{" "}
                </span>
              </Link>
            </li>
          )} */}

          <ClickableContentWrapper className={styles.mobileNavItem} onClick={onContactSupportClick}>
            <span className={classNames(styles.mobileNavItemLink, styles.activeLink)}>
              {" "}
              Contact Support{" "}
            </span>
          </ClickableContentWrapper>

          {isUserLoggedIn && (
            <li className={styles.mobileNavItem}>
              <span className={classNames(styles.mobileNavItemLink)} onClick={onSignOut}>
                {" "}
                Log Out
              </span>
            </li>
          )}
        </ul>
      </nav>
      <div
        className={classNames(styles.navOverlay, menuActive && styles.mobileNavigationActive)}
        onClick={() => setMenuActive(!menuActive)}
      ></div>
    </div>
  );
};

export default MobileNavigation;
